import {
  ref, computed, watch, watchEffect,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'

import store from '@/store'

// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useReservationHandle() {
  // Use toast
  const toast = useToast()

  // Refs
  const refReservationListTable = ref(null)
  const reservationsData = ref(null)

  // Table Handlers
  const tableColumns = [
    'show_details',
    { key: 'name', label: 'Name' },
    { key: 'description', label: 'Description' },
  ]
  const totalRecords = ref(0)
  const sizePerPage = ref(10)
  const currentPage = ref(1)
  // Loading
  const loading = ref(null)
  const loadingRole = ref(null)
  const dataTable = computed(() => store.getters['app-roles/roles'])
  const dataSearchText = ref([])
  const fetchDataRoles = text => {
    loadingRole.value = true

    if (text && dataTable.value.length > 0) {
      dataSearchText.value = dataTable.value.filter(e => e.name.includes(text))
      loadingRole.value = false
    } else {
      dataSearchText.value = []
      store
        .dispatch('app-roles/getCustomerRoles')
        .then(() => {
          loadingRole.value = false
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching reservation',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  }
  // End Table Handler

  // Fetch data clients
  const fetchClients = () => {
    store
      .dispatch('app-roles/authClients')
      .then({})
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching auth client',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // Client Data Handle
  const dataClients = computed(() => store.getters['app-roles/clients'])
  const fetchDataClientById = clientId => {
    store
      .dispatch('app-roles/getAuthzClient', clientId)
      .then(() => {
        loading.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching reservation',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // End Client Data Handle

  // Resource Client Handle
  const dataResource = computed(() => store.getters['app-roles/resources'])
  // End Resource Client Handle

  // Created Role Handle
  const addRole = payload => () => {
    store.dispatch('app-roles/createRole', payload)
  }

  // Client Id
  const clientId = computed(() => store.getters['app-roles/clientId'])

  // Set Permission in Role
  const setPer = payload => () => {
    store.dispatch('app-roles/setPermission', payload)
  }

  // Sort
  const sortBy = ref(null) // createdAt || updatedAt

  // Filter
  const searchTextFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refReservationListTable.value
      ? refReservationListTable.value.localItems.length
      : 0
    return {
      from:
        sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    dataTable.value.refresh()
  }

  const clearFilter = () => {
    searchTextFilter.value = null
    refetchData()
  }

  watch([currentPage, sizePerPage, sortBy], () => {
    refetchData()
  })

  watch(searchTextFilter, value => {
    fetchDataRoles(value)
  })

  watchEffect(() => {
    if (store.state.isStoreReady) {
      // Lấy dữ liệu từ store ở đây
      // Cập nhật các biến trạng thái của custom hook ở đây
      dataTable.value = computed(() => store.getters['app-roles/roles'])
    }
  })

  return {
    // hieuht define
    dataTable,
    dataClients,
    fetchDataClientById,
    fetchDataRoles,
    fetchClients,
    dataResource,
    addRole,
    tableColumns,
    sizePerPage,
    currentPage,
    totalRecords,
    dataMeta,
    sortBy,
    refReservationListTable,
    refetchData,
    clearFilter,
    clientId,
    setPer,
    searchTextFilter,
    reservationsData,
    dataSearchText,

    // Loading
    loading,
    loadingRole,
  }
}
