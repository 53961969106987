var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loadingRole,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('div', {}, [_c('b-row', {
    staticClass: "d-flex-center mb-1"
  }, [_c('b-col', [_c('b-form-input', {
    staticClass: "mr-1",
    attrs: {
      "type": "search",
      "placeholder": 'Name text...',
      "value": _vm.searchTextFilter,
      "reduce": function reduce(val) {
        return val.value;
      },
      "maxlength": "255",
      "debounce": "500",
      "trim": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:searchTextFilter', val);
      }
    },
    model: {
      value: _vm.searchTextFilter,
      callback: function callback($$v) {
        _vm.searchTextFilter = $$v;
      },
      expression: "searchTextFilter"
    }
  })], 1), _c('b-col', [_c('b-button', {
    staticClass: "px-md-75 w-md",
    attrs: {
      "variant": "info"
    },
    on: {
      "click": function click() {
        return _vm.showModal();
      }
    }
  }, [_c('span', {
    staticClass: "text-nowrap"
  }, [_c('feather-icon', {
    staticClass: "cursor-pointer",
    attrs: {
      "icon": "PlusSquareIcon",
      "size": "16"
    }
  }), _vm._v(" Role ")], 1)])], 1)], 1), _c('b-tabs', {
    attrs: {
      "lazy": "",
      "vertical": "",
      "content-class": "col-12 col-md-9 mt-1 mt-md-0",
      "active-nav-item-class": "bg-light-info border-0",
      "pills": "",
      "nav-wrapper-class": "col-md-3 col-12",
      "nav-class": "nav-left"
    }
  }, _vm._l(_vm.dataSearchText.length > 0 ? _vm.dataSearchText : _vm.dataTable, function (role, index) {
    return _c('b-tab', {
      key: index,
      attrs: {
        "active": index === _vm.roleActive
      },
      on: {
        "click": function click($event) {
          _vm.fetchDataClientById(_vm.dataClients && _vm.dataClients.length > 0 ? _vm.dataClients[0].clientId : ''), _vm.roleActive = index;
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('div', {
            staticClass: "d-flex-center justify-content-between w-100"
          }, [_c('div', [_c('feather-icon', {
            staticClass: "mr-50",
            attrs: {
              "icon": "CircleIcon",
              "size": "12"
            }
          }), _c('span', {
            staticClass: "font-weight-bold mr-1"
          }, [_vm._v(_vm._s(role.name) + " ")])], 1), _c('b-button', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index === _vm.roleActive,
              expression: "index === roleActive"
            }, {
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(255, 255, 255, 0.15)',
              expression: "'rgba(255, 255, 255, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "p-25",
            attrs: {
              "size": "sm",
              "variant": "outline-danger text-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.confirmDelete(role.name);
              }
            }
          }, [_c('feather-icon', {
            staticClass: "m-0 p-0",
            attrs: {
              "icon": "XIcon",
              "size": "16"
            }
          })], 1)], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card', {
      staticClass: "border"
    }, [_c('b-tabs', {
      attrs: {
        "content-class": "pt-1",
        "fill": ""
      }
    }, _vm._l(_vm.dataClients, function (client, indexClient) {
      return _c('b-tab', {
        key: indexClient,
        attrs: {
          "lazy": "",
          "title": client.clientId
        },
        on: {
          "click": function click($event) {
            _vm.fetchDataClientById(client.clientId), _vm.loading = true, _vm.getPermissionInRole(client.id, role.name);
          }
        }
      }, [_c('b-overlay', {
        attrs: {
          "show": _vm.loading,
          "rounded": "sm",
          "no-fade": "",
          "variant": "transparent",
          "opacity": 0.5
        }
      }, [_c('RoleDetail', {
        attrs: {
          "all-resources": _vm.dataResource,
          "data-role": role,
          "data-permission": _vm.dataPermission,
          "client-id": _vm.clientId
        },
        on: {
          "setPermission": function setPermission($event) {
            return _vm.setPermissionInRole($event, role);
          }
        }
      })], 1)], 1);
    }), 1)], 1)], 1);
  }), 1), _c('b-modal', {
    attrs: {
      "id": "my-modal",
      "hide-footer": "",
      "title": "Add Role",
      "centered": ""
    }
  }, [_c('CreateRole', {
    on: {
      "hidden": _vm.hideModal,
      "insertRole": _vm.insertRole
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }