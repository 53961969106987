var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.clientId ? _c('div', [_c('b-tabs', {
    attrs: {
      "vertical": "",
      "nav-wrapper-class": "nav-vertical"
    }
  }, [_vm._l(_vm.allResources, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {}, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('b-card-text', [_c('div', {
      staticClass: "demo-inline-spacing container border-left"
    }, _vm._l(item.scopes, function (scoped, indexScope) {
      return _c('b-form-group', {
        key: indexScope,
        staticClass: "mr-auto"
      }, [_c('label', {
        staticClass: "text-uppercase font-weight-bolder text-warning"
      }, [_vm._v(_vm._s(scoped.name))]), _c('b-form-radio-group', {
        staticClass: "mb-1",
        staticStyle: {
          "display": "flex",
          "flex-direction": "column"
        },
        attrs: {
          "vertical": "",
          "options": _vm.options,
          "value-field": "value",
          "text-field": "text",
          "disabled-field": "disabled"
        },
        on: {
          "change": function change(selectedValue) {
            return _vm.setPermission(item, scoped, selectedValue);
          }
        },
        model: {
          value: _vm.selected["".concat(item.id, "-").concat(scoped.id)],
          callback: function callback($$v) {
            _vm.$set(_vm.selected, "".concat(item.id, "-").concat(scoped.id), $$v);
          },
          expression: "selected[`${item.id}-${scoped.id}`]"
        }
      })], 1);
    }), 1)])], 1);
  }), _c('div', {
    staticClass: "demo-inline-spacing justify-content-sm-center"
  }, [_c('b-button', {
    attrs: {
      "size": "sx",
      "variant": "outline-success",
      "disabled": _vm.disableSubmit
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "RefreshCwIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Save")])], 1)], 1)], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }