import { useToast } from 'vue-toastification/composition'

import api from '@/api/'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    roles: [],
    clients: [],
    resources: [],
    clientId: null,
  },
  getters: {
    roles: ({ roles }) => roles,
    clients: ({ clients }) => clients,
    resources: ({ resources }) => resources,
    clientId: ({ clientId }) => clientId,
  },
  mutations: {
    SET_DATA_CLIENT(state, data) {
      state.clients = data
    },

    SET_DATA_ROLE(state, data) {
      state.roles = data
    },

    SET_DATA_RESOURCE(state, data) {
      state.resources = data
    },

    SET_CLIENT_ID(state, data) {
      state.clientId = data
    },
  },

  actions: {
    async getAuthzClient({ commit, state }, clientId) {
      try {
        if (state.clients.length > 0) {
          const clientIdCms = state.clients.find(
            element => element.clientId === clientId,
          )
          const resource = await api.role.getResouceByClientId(clientIdCms.id)
          if (resource.status === 200) {
            commit('SET_CLIENT_ID', clientIdCms.id)
            const resources = resource.data.items
            commit('SET_DATA_RESOURCE', resources)
          }
        } else {
          commit('SET_DATA_RESOURCE', [])
        }
      } catch (error) {
        console.error(error)
        useToast({
          component: ToastificationContent,
          props: {
            title: 'Error fetch clients',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },

    async authClients({ commit }) {
      const data = await api.role.authzClient()
      if (data.status === 200 && data.data && data.data.items.length > 0) {
        commit('SET_DATA_CLIENT', data.data.items)
      } else {
        commit('SET_DATA_CLIENT', [])
      }
    },

    async getCustomerRoles({ commit }) {
      const data = await api.role.getCustomRole()
      if (data.status === 200) {
        commit('SET_DATA_ROLE', data.data.items || [])
      }
    },

    // eslint-disable-next-line no-unused-vars
    createRole({ commit }, payload) {
      return api.role.createdRole(payload)
    },

    // eslint-disable-next-line no-empty-pattern
    setPermission({}, objectPayload) {
      const payload = objectPayload.payload
      const clientId = objectPayload.clientId
      const roleName = objectPayload.roleName
      return api.role.setPermissionInRole(payload, clientId, roleName)
    },

    // eslint-disable-next-line no-empty-pattern
    getPermission({ commit }, objectpayload) {
      const clientId = objectpayload.clientId
      const roleName = objectpayload.roleName
      commit('SET_CLIENT_ID', objectpayload.clientId)

      return api.role.getPermissionInRole(clientId, roleName)
    },

    // eslint-disable-next-line no-empty-pattern
    deleteRole({}, roleName) {
      return api.role.deleteRole(roleName)
    },
  },
}
