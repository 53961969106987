<template>
  <div v-if="clientId">
    <b-tabs
      vertical
      nav-wrapper-class="nav-vertical"
    >
      <div
        v-for="(item, index) in allResources"
        :key="index"
      >
        <div class="">
          {{ item.name }}
        </div>
        <b-card-text>
          <div class="demo-inline-spacing container border-left">
            <b-form-group
              v-for="(scoped, indexScope) in item.scopes"
              :key="indexScope"
              class="mr-auto"
            >
              <label class="text-uppercase font-weight-bolder text-warning">{{
                scoped.name
              }}</label>

              <b-form-radio-group
                v-model="selected[`${item.id}-${scoped.id}`]"
                vertical
                :options="options"
                style="display: flex; flex-direction: column"
                class="mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
                @change="
                  (selectedValue) => setPermission(item, scoped, selectedValue)
                "
              />
            </b-form-group>
          </div>
        </b-card-text>
      </div>
      <div class="demo-inline-spacing justify-content-sm-center">
        <b-button
          size="sx"
          variant="outline-success"
          :disabled="disableSubmit"
          @click="submit"
        >
          <feather-icon
            icon="RefreshCwIcon"
            class="mr-50"
          />
          <span
            class="align-middle"
          >Save</span>
        </b-button>
      </div>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs,
  // BTab,
  BCardText,
  BFormGroup,
  BFormRadioGroup,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BTabs,
    BFormGroup,
    // BTab,
    BButton,
    BFormRadioGroup,
  },

  props: {
    allResources: {
      type: Array,
      default: () => [],
    },

    dataRole: {
      type: Object,
      default: () => {},
    },

    clientId: {
      type: String,
      default: () => '',
    },

    dataPermission: {
      type: Array,
      default: () => [],
      require: true,
    },
  },

  data() {
    return {
      options: [
        { text: 'NO', value: 'NONE' },
        { text: 'ALL', value: 'ALL' },
        { text: 'OWN', value: 'OWNED' },
      ],
      selected: {},

      disableSubmit: true,

      dataInsert: [
        {
          scopes: [
            {
              id: '',
              name: '',
              power: '',
            },
          ],
        },
      ],
    }
  },

  watch: {
    dataPermission: {
      handler(data) {
        this.dataInsert = this.allResources.map(({ id, name, scopes }) => ({
          rsId: id,
          rsName: name,
          scopes,
        }))

        const dataMap = data.reduce((map, item) => {
          item.attachedScopes.forEach(scope => {
            map.set(scope.id, scope.power)
          })
          return map
        }, new Map())

        this.dataInsert = this.dataInsert.map(item => {
          const scopes = item.scopes?.map(scope => {
            const power = dataMap.get(scope.id) || 'NONE'
            return { ...scope, power }
          })
          return { ...item, scopes }
        })

        this.allResources.forEach(element => {
          if (element.scopes) {
            element.scopes.forEach(scope => {
              this.options.forEach(option => {
                const selected = this.checkedIsScope(element, scope, option.value)
                if (selected) {
                  this.$set(this.selected, `${element.id}-${scope.id}`, selected)
                }
              })
            })
          }
        })
      },
    },
  },

  methods: {
    setPermission(item, scoped, option) {
      this.disableSubmit = false

      for (const item of this.dataInsert) {
        if (item.scopes) {
          const scopeIndex = item.scopes.findIndex(s => s.id === scoped.id)

          if (scopeIndex !== -1) {
            item.scopes[scopeIndex].power = option
          }
        }
      }
      // console.log('this.dataInsert', this.dataInsert)
    },

    submit() {
      try {
        this.$emit('setPermission', this.dataInsert)
      } catch (error) {
        console.error(error)
      }
    },

    checkedIsScope(item, scope, value) {
      const idScope = scope.id
      let isCheck = false
      this.dataPermission.forEach(element => {
        if (isCheck === false && element.rsId === item.id) {
          // eslint-disable-next-line consistent-return
          element.attachedScopes.forEach(scope => {
            if (scope.id === idScope && scope.power === value) {
              isCheck = value
            }
          })
        }
      })
      return isCheck
    },
  },
}
</script>
